import React, { useState, useRef } from "react";
import '../styles/interviewPrep.css';
import '../styles/ContentGatheringPhase3.css'
import ReactPlayer from "react-player";
import Questions from "../components/Questions";
import { useEffect } from "react";
import {
  IPopup,
  Status,
  ISubCategory,
  staticDataForPhases,
  staticResumeData,
  lorData
} from "../utils/defaultData";
import { industrySampleData } from '../utils/industrySampleData';
import { Link } from "react-router-dom";
import { updateStatusAction } from "../actions/updateStatusAction";
import { useDispatch, useSelector } from "react-redux";
import { CategoryStatus, InitialStore, Source } from "../store/initialState";
import { useHistory, useLocation } from "react-router-dom";
import SubmitModal from "../components/SubmitModal";
import { Helmet } from "react-helmet";
import Comments from "../components/Comments";
import { APP_ROUTES } from "../utils/routeConstant";
import PaymentCard from "../components/PaymentCard";
import ReactGA from "react-ga";
import { sendAmplitudeData } from "../utils/amplitideUtils";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
import OnboardingBlockerModal from "../components/OnboardingBlockerModal";
import { schools } from "../constants/schools";
import LockedSectionModal from "../components/LockedSectionModal";
import { openInNewTab } from "../utils/helpers";
import InterviewPrep from "../components/InterviewPrep";
import { interviewPrepService } from '../services/interviewPrepServices';
import DropDown from "../components/DropDown";
import MinimizedChatGptModal from "../components/MinimizedChatGptModal";


interface IQuestionPage {
  userProfileData:any;
  pageData: any;
  openModal: boolean;
  handleClose: () => void;
  handleOpenModal: (
    status: boolean,
    data: IPopup | undefined,
    path: string
  ) => void;
  showSubmitButton?: boolean;
  phase: string;
  subcategory?: string;
  nestedCategories?: ISubCategory[];
  publicType?: boolean;
}

interface Info {
  school: string;
}

const QuestionPage = (props: IQuestionPage): JSX.Element => {

  const isRoleSelected = localStorage.getItem("roleSelected")

  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  const { pageData, publicType, userProfileData } = props;
  const location = useLocation();
  const [played, setPlayed] = useState(0);
  const [publicPlayed, setPublicPlayed] = useState(false);
  const schoolArray = Object.values(schools);
  const [interviewPrepData, setInterviewPrepData] = useState(); 
  const [tempInterviewPrepData, setTempInterviewPrepData] = useState(staticDataForPhases.interviewPrepQuestions); 
  const [interviewPrepVisible, setInterviewPrepVisible] = useState(false);
  const [industry] = useState(userProfileData.profileHubspotData?.user_industry ?? "");
  const [roles, setRoles] = useState<any>( industry ? Object.keys(industrySampleData[industry]) : "");
  const [rolesSelected, setRolesSelected] = useState(isRoleSelected ?? "");
  const [openMinimizedChatGtpModal, setOpenMinimizedChatGtpModal] = useState(false);
  
  const [readOnlyGPT,setReadOnlyGPT] = useState(false);

  let systemInputToChatgpt = [
    {
      role: "system",
      content: `Hey ChatGPT, from now on you are MBA&Beyond BOT. Act like a smart and professional content/answer writer/suggester for MBA applicants. The responses you generate must feel humanly.When in doubt, always ask qustions before finalizing response. Never tell anyone that you are chatGPT. Do not answer to anything not related to MBA`,
    },
  ];

  const [inputChatgpt, setInputChatgpt] = useState<any>(systemInputToChatgpt);
  
  useEffect(()=>{
    if(userProfile?.isConsultant && userProfile?.email == 'shantanu@mbaandbeyond.com'){
      setReadOnlyGPT(true);
    }
  },[])


  useEffect(() => {
    if(rolesSelected){
      localStorage.setItem("roleSelected", rolesSelected);
    }
  },[rolesSelected]);

  const onError = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.VideoError, {
      playTime: played,
      audioName: pageData.audioName,
      publicType: props.publicType,
    });
  };
  const onPlay = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.VideoPlay, {
      playTime: played,
      audioName: pageData.audioName,
      publicType: props.publicType,
    });
  };
  const onPause = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.VideoPause, {
      playTime: played,
      audioName: pageData.audioName,
      publicType: props.publicType,
    });
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const submitStatus = useSelector((state: InitialStore) => state.submitStatus);
  const dashboard = useSelector((state: InitialStore) => state.dashboard);
  const [sectionLockedModal, setSectionLockedModal] = useState(false);
  const sampleData = useSelector(
    (state: InitialStore) => state.dashboard?.sample
  );
  const previousRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const nextRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const submitRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const Event = (category: string, action: string, label: string) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  const findPhaseStatus = (key:string) => {
    if(dashboard && dashboard?.categories) {
      return dashboard.categories.find((o: { key: string }) => o.key === key)?.status
    }
    return null;
  }

  const [openRegisterPopup, setOpenRegisterPopup] = useState(false);
  const [registerPopupContent, setRegisterPopupContent] = useState({
    linkText: "Check Sample",
    heading: "Sample for Part 1",
    content: "Test",
  });
  /**
   * close modal of audio modal
   */
  const handleClose = (): void => {
    dispatch(updateStatusAction.statusUpdateReset());
    setOpenModal(false);
  };

  const handleLockModalClose = (): void => {
    setSectionLockedModal(false);
  };

  const convertObjectToArray = (array: Array<Info>, key: any) => {
    const initialValue: any = [];
    for (const property in array) {
      const key = array[property].school;
      //initialValue[] = { ...array[property], ...schools[key] };
      initialValue.push({ ...array[property], ...schools[key] });
    }
    return initialValue;
  };

  const handleSchoolStructureClick = (e: any, id: any) => {
    e.preventDefault();
    history.push(`${APP_ROUTES.LATEST_SCHOOLS}?s=${id}`);
  };

  const checkIfSavedSchool = (school_str: string) => {
    let converted = convertObjectToArray(dashboard?.selectedSchools, "school");
    let obj = converted.find((o: { id: string }) => o.id == school_str);
    if (obj) return true;
    return false;
  };

  /**
   * Register Popup
   */

  const handleRegisterPopupClose = (): void => {
    setOpenRegisterPopup(false);
    sendAmplitudeData(AMPLITUDE_EVENTS.PublicLockContentPopupClosed, {});
  };

  const handleRegisterPopupOpen = (e: any, data: any): void => {
    setRegisterPopupContent(data);
    setOpenRegisterPopup(true);
  };

  /**
   * submit modal
   */
  useEffect(() => {
    if (submitStatus === CategoryStatus.SUBMITTED) {
      setOpenModal(true);
    }
  }, [submitStatus]);
  /**
   * for opening audio modal
   */
  useEffect(() => {
    let publicShow = true;
    const previousStatus = localStorage.getItem("audioStatus");
    const parsedStatus: string[] | undefined =
      previousStatus && JSON.parse(previousStatus);
    const status = parsedStatus?.includes(location.pathname) ? false : true;

    // Check for public type and single audio play
    if (parsedStatus && parsedStatus.length > 0 && props.publicType) {
      publicShow = false;
      setPublicPlayed(true);
    }

    props.pageData.popup &&
      publicShow &&
      props.handleOpenModal(status, props.pageData.popup, location.pathname);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageData]);
  const nestedCategoriesLinkList = props.nestedCategories && [
    ...props.nestedCategories,
  ];
  /** calculated last nested category */
  const isLastItem =
    nestedCategoriesLinkList &&
    location.pathname.includes(
      nestedCategoriesLinkList[nestedCategoriesLinkList?.length - 1].key
    );
  /** calculated first nested category */
  const isFirstItem =
    nestedCategoriesLinkList &&
    location.pathname.includes(nestedCategoriesLinkList[0].key);
  /**submit button handler */
  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (submitRef && submitRef?.current) {
      submitRef.current.focus();
    }
    sendAmplitudeData(AMPLITUDE_EVENTS.SubmitClick, {
      publicType: props.publicType,
    });
    Event("Click", "submit", props.phase);
    //dispatch(saveAnswerAction.updateSavedAnswersSuccess(pageAnswer));
    if (userProfile.profileDataId) {
      dispatch(
        updateStatusAction.update({
          userProfileDataId: userProfile.profileDataId,
          category: props.phase,
          status: Status.COMPLETED,
        })
      );
    }
  };
  /** go back/ previous button handler */
  const handlePrevious = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (previousRef && previousRef?.current) {
      previousRef.current.focus();
    }
    const currentLink =
      nestedCategoriesLinkList &&
      nestedCategoriesLinkList?.findIndex((item) =>
        location.pathname.includes(item.key)
      );
    sendAmplitudeData(AMPLITUDE_EVENTS.PreviousClick, {
      publicType: props.publicType,
    });
    nestedCategoriesLinkList && currentLink && currentLink - 1 > -1
      ? Event("Click", "Previous", nestedCategoriesLinkList[currentLink].key)
      : void 0;
    //dispatch(saveAnswerAction.updateSavedAnswersSuccess(pageAnswer));
    nestedCategoriesLinkList && currentLink && currentLink - 1 > -1
      ? history.push(`${nestedCategoriesLinkList[currentLink - 1].key}`)
      : void 0;
  };

  /** next button handler */
  const handleNext = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (nextRef && nextRef?.current) {
      nextRef.current.focus();
    }
    const currentLink = nestedCategoriesLinkList?.findIndex((item) =>
      location.pathname.includes(item.key)
    );
    nestedCategoriesLinkList && currentLink && currentLink - 1 > -1
      ? Event("Click", "Next", nestedCategoriesLinkList[currentLink].key)
      : void 0;
    //dispatch(saveAnswerAction.updateSavedAnswersSuccess(pageAnswer));
    sendAmplitudeData(AMPLITUDE_EVENTS.NextClick, {
      publicType: props.publicType,
    });
    nestedCategoriesLinkList &&
      currentLink !== undefined &&
      currentLink + 1 < nestedCategoriesLinkList.length
      ? history.push(`${nestedCategoriesLinkList[currentLink + 1].key}`)
      : void 0;
  };

  // Handle Draft Resume
  const handleDraftResume = (e: any, resumeDocId: any): void => {
    e.preventDefault();
    if (resumeDocId) {
      const finalLink = `https://docs.google.com/document/d/${resumeDocId}/edit`;
      openInNewTab(finalLink);
    }
  };

  // Fetch Question Data according to applicantId
  useEffect(()=>{

    let applicantId: any;
    if(userProfileData.isConsultant == true){
      applicantId = userProfileData.applicantData.id;
    } else {
      applicantId = userProfileData.id;
    }

    const fetchQuestionData = async () => {
      try {
        const questionData = await interviewPrepService.getQuestionData(applicantId);
        const responseQuestionData = questionData.data;

        const modifiedEmployees = tempInterviewPrepData.map(obj => {
          const matchingQuestion = responseQuestionData.find((item:any) => obj.questionId == item.questionId);
          if (matchingQuestion) {
            return { ...obj, videoUrl: matchingQuestion.videoUrl };
          }
          return obj;
        });
        setTempInterviewPrepData(modifiedEmployees)
        setInterviewPrepVisible(true)
        
      } catch (error) {
        setInterviewPrepVisible(true)
      }
    };
    fetchQuestionData();
  },[])

  return (
    <>
      <Helmet>
        <title>{pageData.heading} | MBA and Beyond</title>
      </Helmet>
      <div className="grid grid-cols-1 lg:grid-cols-3 px-8 ">
        <div className="md:col-span-2">
          <div>
          <div className="">
            <h1 className="font-bold text-xl py-2 text-brand-darkblue">
              {pageData.heading}
            </h1>
            <h2 className="font-base text-base py-2 text-brand-grey">
              <div
                className="text-sm text-brand-grey"
                dangerouslySetInnerHTML={{ __html: pageData.description.replace(/MBA/g, localStorage.getItem("selectedProgram") || "MBA") }}
              />
            </h2>
            {(pageData.videoFile || pageData.audioFile) && (
              <div className="relative mt-4">
                {publicPlayed && (
                  <div
                    className="cursor-pointer z-10 opacity-0 absolute w-full h-full pt-0 mx-auto inset-y-0 inset-x-0 bg-gray-400 rounded-3xl text-white"
                    onClick={() => setOpenRegisterPopup(true)}
                  ></div>
                )}
                <ReactPlayer
                  url={
                    pageData.showVideo ? pageData.videoFile : pageData.audioFile
                  }
                  className="react-player"
                  controls
                  width="100%"
                  height={pageData.showVideo ? "320px" : "60px"}
                  onProgress={(progress) => {
                    setPlayed(progress.playedSeconds);
                  }}
                  onError={onError}
                  onPlay={onPlay}
                  onPause={onPause}
                  onContextMenu={(e: React.SyntheticEvent) =>
                    e.preventDefault()
                  }
                  // Disable download button
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                />
                {!pageData.showVideo && pageData.audioName && (
                  <div className="text-xs absolute top-4 left-14 font-bold">
                    {pageData.audioName}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-8">
            {
              props.phase === "phase3" && (
                <>
                  <div className="mb-8 flex flex-wrap gap-8 justify-between items-end">
                    { industry && roles && (
                        <DropDown 
                          placeholder="Choose your roles"  
                          options={roles}
                          selected={rolesSelected} 
                          setSelected={setRolesSelected}
                      />
                    )}

                    { !industry && (
                        <>
                        <p className="text-xs text-left lg:text-right w-full mt-10" style={{color: '#ff0000'}}>**Please select your industry in the overview section to access the samples. <Link to={`${APP_ROUTES.DASHBOARD}`} rel="noopener noreferrer" style={{color: '#0370ff', cursor: 'pointer', fontWeight: 'bold'}}>Go to Overview</Link></p>
                        </>
                    )}
                    
                    {props.subcategory && industry && rolesSelected && (
                      <>
                        {props.subcategory &&
                          sampleData &&
                          sampleData[props.subcategory] &&
                          rolesSelected &&
                          !publicType ? (
                          <Link
                            className="flex text-sm text-brand-grey justify-end  -mt-2"
                            to={`${APP_ROUTES.LATEST_SAMPLE}/?industry=${industry}&role=${rolesSelected}&ref=${props.subcategory}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="/images/readFile.svg"
                              className="w-5 h-5"
                              alt={"read sample"}
                            />
                            <span>Read Sample</span>
                          </Link>
                        ) : (
                          <>
                            <div
                              className="flex text-sm text-brand-grey justify-end -mt-2 cursor-pointer"
                              onClick={() => setOpenRegisterPopup(true)}
                            >
                              <img
                                src="/images/readFile.svg"
                                className="w-5 h-5"
                                alt={"read sample"}
                              />
                              <span>Read Sample</span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {
                    readOnlyGPT && (
                      <>
                      <div className="phase3_MinimizedChatGptModal" onClick={()=>setOpenMinimizedChatGtpModal(!openMinimizedChatGtpModal)} >
                        {openMinimizedChatGtpModal ? 
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className="phase3_MinimizedChatGptModal_Icon">
                            <path fill="#ffffff" d="M 40.783203 7.2714844 A 2.0002 2.0002 0 0 0 39.386719 7.8867188 L 25.050781 22.222656 L 10.714844 7.8867188 A 2.0002 2.0002 0 0 0 9.2792969 7.2792969 A 2.0002 2.0002 0 0 0 7.8867188 10.714844 L 22.222656 25.050781 L 7.8867188 39.386719 A 2.0002 2.0002 0 1 0 10.714844 42.214844 L 25.050781 27.878906 L 39.386719 42.214844 A 2.0002 2.0002 0 1 0 42.214844 39.386719 L 27.878906 25.050781 L 42.214844 10.714844 A 2.0002 2.0002 0 0 0 40.783203 7.2714844 z"></path>
                          </svg> 
                          :
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="phase3_MinimizedChatGptModal_Icon">
                            <path fill="#ffffff" d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"/>
                          </svg>
                        }
                      </div>
                      { openMinimizedChatGtpModal && <MinimizedChatGptModal inputChatgpt={inputChatgpt} setInputChatgpt={setInputChatgpt} />}
                      </>
                    )
                  }
                </>
              )
            }

            <div className="p-3 px-6 border rounded-3xl mb-6 mt-2">
              <>
                <Questions pageData={pageData} publicType={publicType} />
              </>

              {(props.phase === "phase8" || props.phase === "phase9") && (
                <>
                  {staticDataForPhases[props.phase].map((item, index) => {
                    if (item.type === "audio") {
                      return (
                        <>
                          <div className="mb-12">
                            <h2 className="font-semibold text-lg">
                              {item.heading}
                            </h2>
                            <div className="flex items-center mb-2">
                              <div className="my-1 flex flex-1 relative">
                                <ReactPlayer
                                  url={item.audioFile}
                                  className="react-player"
                                  controls
                                  width="100%"
                                  height={"60px"}
                                  onError={onError}
                                  onPlay={onPlay}
                                  onPause={onPause}
                                  onContextMenu={(e: React.SyntheticEvent) =>
                                    e.preventDefault()
                                  }
                                  // Disable download button
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }}
                                />
                                <div className="text-xs absolute top-4 left-14 font-bold">
                                  {item.audioName}
                                </div>
                              </div>
                            </div>
                            <p className="text-sm">{item.description}</p>
                          </div>
                        </>
                      );
                    } else if (item.type === "file") {
                      return (
                        <>
                          <div className="mb-12">
                            <h2 className="font-semibold text-lg">
                              {item.heading}
                            </h2>
                            <p className="text-sm">{item?.description}</p>
                            <div className="flex items-center mb-2">
                              <p>
                                <a
                                  className="py-1 text-brand-torquise text-sm underline"
                                  href={item.fileUrl}
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.fileName}
                                </a>
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
                  {(props.phase === "phase8") && (
                    <>
                      <div className="mb-12">
                        <h2 className="font-semibold text-lg">
                          Resume Template Samples
                        </h2>
                        <p className="text-sm">You must use this Resume Template to start building your Resume</p>
                        {staticResumeData.map((item, index) => {
                          return (
                            <>
                              <div className="flex items-center mb-2" key={index}>
                                <p>
                                  <a
                                    className="py-1 text-brand-torquise text-sm underline"
                                    href={item.fileUrl}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item.fileName}
                                  </a>
                                </p>
                              </div>
                            </>
                          )
                        })}

                      </div>
                    </>
                  )}
                  {/* Draft redume Link */}
                  {(props.phase === "phase8") && (
                    <>
                      <div>
                        {dashboard?.resumeDoc && dashboard?.resumeDoc.resumeDocId ? (
                          <div className="flex w-full justify-center ">
                            <button
                              type="submit"
                              className="border-2 font-bold text-md border-brand-torquise p-2 rounded-3xl text-center w-50 text-white bg-brand-torquise focus:outline-none mb-4"
                              onClick={(e) => {
                                handleDraftResume(e, dashboard?.resumeDoc.resumeDocId);
                              }}
                            >
                              {userProfile?.isConsultant
                                ? "View Drafted Resume"
                                : "Draft Your Resume"}
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}

                  {/* Phase9 - Interview Prep */}
                  {(props.phase === "phase9") && interviewPrepVisible && (
                    <>
                    <div className="interviewPrepQuestionContainer">
                      <div>
                        <p className="font-semibold text-lg">Interview Mock</p>
                        <p className="text-sm">In this section, you will have to answer 7 questions including personal and behavioral questions. You will have 5 mins to answer each question. (Time Limit: 5 minutes & Size Limit: 1GB)</p>
                      </div>
                      {tempInterviewPrepData && tempInterviewPrepData.map((item, index)=>{
                        if(item.status || item?.videoUrl){
                          return(
                            <>
                            <div className="interviewPrepQuestion" key={index}>
                              <p className="interviewPrepQuestionName">{item.questionName}</p>
                              <InterviewPrep questionId={item.questionId} applicantId={userProfileData.id} questionName={item.questionName} questionVideoURL={ item?.videoUrl} isConsultant={userProfileData.isConsultant} />
                            </div>
                            </>
                          )
                        }
                      })}
                    </div>
                    </>
                  )}
                </>
              )}

              {props.phase === "phase6" && (
                <>
                  {userProfile?.source === Source.Internal ? (
                    <>
                      <div className="flex flex-col flex-wrap md:flex-row justify-center mt-8 mb-16">
                        <div>
                          {dashboard?.selectedSchools &&
                            Object.values(dashboard?.selectedSchools).length > 0 ? (
                            <>
                              <div className="mx-8 grid gap-8 row-gap-5 sm:grid-cols-2 lg:grid-cols-2 justify-center">
                                {schoolArray &&
                                  schoolArray.length > 0 &&
                                  schoolArray.map((value: any) => {
                                    if (
                                      checkIfSavedSchool(value.id)
                                    ) {
                                      return (
                                        <>
                                          <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
                                            <div>
                                              <div className="flex mb-4">
                                                <img
                                                  className="object-cover w-16 h-16 border mr-4 p-1"
                                                  src={value.img}
                                                  alt={value.name}
                                                />
                                                <div className="flex flex-col justify-center">
                                                  <p className="text-base font-semibold">
                                                    {" "}
                                                    {value.name}
                                                  </p>
                                                  <p className="text-sm text-brand-grey hidden">
                                                    {value.deadline}
                                                  </p>
                                                </div>
                                              </div>
                                              <h6 className="mb-2 font-semibold leading-5 hidden">
                                                {value.name}
                                              </h6>
                                              <p className="mb-4 text-sm text-brand-gray hidden">
                                                {value.overviewDescription}
                                              </p>
                                            </div>
                                            <div className="flex items-center">
                                              <button
                                                className={`${findPhaseStatus("phase7") === Status.LOCKED ? "opacity-50" : ""} mt-2 border-2 font-medium text-xs border-brand-torquise py-2 px-4 inline-flex items-center rounded-3xl text-center text-white bg-brand-torquise hover:bg-white hover:text-brand-torquise focus:outline-none`}
                                                onClick={(e) =>
                                                  findPhaseStatus("phase7") === Status.LOCKED ? setSectionLockedModal(true) :
                                                    handleSchoolStructureClick(e,value.id)
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="w-4 h-4 mr-2"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke="currentColor"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                                  />
                                                </svg>
                                                <span>
                                                  {value?.overViewCta ||
                                                    "Check essay template"}
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  })}
                              </div>
                            </>
                          ) : (
                            <>
                              <p>No Schools are finalised yet</p>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="flex w-full justify-between flex-row-reverse">
            {!userProfile?.isConsultant &&
              (props.showSubmitButton || isLastItem) ? (
              <button
                type="submit"
                ref={submitRef}
                className="border-2 font-medium text-sm border-brand-torquise p-2 rounded-3xl text-center w-36 text-brand-torquise hover:bg-brand-torquise hover:text-white focus:outline-none mt-2"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </button>
            ) : (
              <></>
            )}
            {props.phase === "phase3" &&
              !props.showSubmitButton &&
              !isLastItem && (
                <button
                  type="submit"
                  ref={nextRef}
                  className="border-2 font-medium text-sm border-brand-torquise p-2 rounded-3xl text-center w-36 text-brand-torquise hover:bg-brand-torquise hover:text-white focus:outline-none mt-2"
                  onClick={(e) => {
                    handleNext(e);
                  }}
                >
                  Next
                </button>
              )}
            {props.phase === "phase3" &&
              !props.showSubmitButton &&
              !isFirstItem && (
                <button
                  type="submit"
                  ref={previousRef}
                  className="border-2 font-medium text-sm border-brand-torquise p-2 rounded-3xl text-center w-36 text-brand-torquise hover:bg-brand-torquise hover:text-white focus:outline-none mt-2"
                  onClick={(e) => {
                    handlePrevious(e);
                  }}
                >
                  Previous
                </button>
              )}
          </div>
          </div>


          <div>
            {(props.phase === "phase8" && (
            <>
                  <div className="mt-12">

                    <div className="p-3 pt-8 px-6 border rounded-3xl mb-6 mt-2">
                      {lorData.lorResources.map((item, index)=>{
                        if (item.type === "audio") {
                          return (
                            <>
                              <div className="mb-12" key={index} >
                                <h2 className="font-semibold text-lg">
                                  {item.heading}
                                </h2>
                                <div className="flex items-center mb-2">
                                  <div className="my-1 flex flex-1 relative">
                                    <ReactPlayer
                                      url={item.audioFile}
                                      className="react-player"
                                      controls
                                      width="100%"
                                      height={"60px"}
                                      onError={onError}
                                      onPlay={onPlay}
                                      onPause={onPause}
                                      onContextMenu={(e: React.SyntheticEvent) =>
                                        e.preventDefault()
                                      }
                                      // Disable download button
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                          },
                                        },
                                      }}
                                    />
                                    <div className="text-xs absolute top-4 left-14 font-bold">
                                      {item.audioName}
                                    </div>
                                  </div>
                                </div>
                                <p className="text-sm">{item.description.replace(/MBA/g, localStorage.getItem("selectedProgram") || "MBA")}</p>
                              </div>
                            </>
                          );
                        }
                      })}
                      {(props.phase === "phase8") && (
                        <>
                          <div className="mb-12">
                            <h2 className="font-semibold text-lg">
                              LOR's Samples
                            </h2>
                            {lorData.lorSamples.map((item, index) => {
                              return (
                                <>
                                  <div className="flex items-center mb-2" key={index}>
                                    <p>
                                      <a
                                        className="py-1 text-brand-torquise text-sm underline"
                                        href={item.fileUrl}
                                        download
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.fileName}
                                      </a>
                                    </p>
                                  </div>
                                </>
                              )
                            })}

                          </div>
                        </>
                      )}
                    </div>
                  </div>
            </>
            ))}
          </div>
        </div>
        <div className=" ml-8 lg:block hidden border-l relative lg:-mt-8 -mb-12">
          {userProfile.isConsultant === true ||
            userProfile?.source === Source.Internal ? (
            <Comments heading={pageData.heading} />
          ) : (
            <>{<PaymentCard />}</>
          )}
        </div>
      </div>
      {sectionLockedModal ? <LockedSectionModal handleClose={handleLockModalClose} /> : <></>}
      {openModal ? <SubmitModal handleClose={handleClose} /> : <></>}
      {openRegisterPopup ? (
        <OnboardingBlockerModal
          modalContent={registerPopupContent}
          handleClose={handleRegisterPopupClose}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default QuestionPage;
