import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { schools } from "../constants/schools";
import { sendAmplitudeData } from "../utils/amplitideUtils";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
import { APP_ROUTES } from "../utils/routeConstant";
import SimpleContentModal from "./SimpleContentModal";

const SchoolSection = (props: any) => {
  const history = useHistory();
  const school = props.school;
  const question = props.question;
  const publicType = props.publicType;

  const redirectTo = (route: string): void => {
    history.push(route);
  };

  const [openStructureSample, setOpenStructureSample] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleStructureSampleClose = (): void => {
    setOpenStructureSample(false);
  };

  const handleStructureSampleOpen = (e: any, data: any): void => {
    setModalContent(data);
    setOpenStructureSample(true);
    sendAmplitudeData(AMPLITUDE_EVENTS.StructurePageSampleClick, {
      school: school.id,
      question: question,
      publicType: props.publicType,
    });
  };

  return (
    <>
      {openStructureSample ? (
        <SimpleContentModal
          modalContent={modalContent}
          handleClose={handleStructureSampleClose}
        />
      ) : (
        <></>
      )}

      {props.sectiondetails.structure && (
        <>
          <div className="py-0 md:py-4 mx-auto">
            <div className="flex flex-wrap md:-m-4">
              <div className="px-0 md:p-4 md:px-6 md:w-1/2">
                <h2 className="font-semibold text-brand-grey text-sm md:text-base">
                  Follow this template to draft your essay
                </h2>
              </div>
              <div className="p-4 px-6 md:w-1/2 hidden md:block">
                <h2 className="font-semibold text-brand-grey ml-8 animate-pulse text-sm md:text-base">
                  Complete the below sections and get first essay draft from our
                  consultants{" "}
                </h2>
              </div>
            </div>
          </div>
        </>
      )}
      <section className="">
        {props.sectiondetails.structure &&
          props.sectiondetails.structure.map((details: any, i: number) => (
            <section className="text-gray-600 body-font">
              <div className="mx-auto">
                <div className="flex flex-wrap md:-m-4 group mb-8 md:mb-0">
                  <div className="px-0 py-4 md:pl-6 md:pr-2 md:w-1/2">
                    <div className="">
                      <div className="p-4 md:p-6 mb-2 duration-300 transform bg-white border-1 border rounded-2xl border-gray-300 border-dashed">
                        <div className="flex items-center mb-1">
                          <p className="flex items-center justify-center w-6 h-6 md:w-8 md:h-8 mr-2 text-xs md:text-base font-bold text-white bg-brand-torquise rounded-full bg-white">
                            {i + 1}
                          </p>
                          <p className="text-sm md:text-base font-semibold text-brand-darkblue leading-5">
                            {details.left.heading}
                          </p>
                        </div>
                        <p className="text-xs md:text-sm text-brand-grey">
                          {details.left.description}
                        </p>
                        {details.left.sample ? (
                          <>
                            <button
                              className=" text-xs mt-4 px-4 py-2 rounded-3xl inline-flex items-center text-brand-grey focus:outline-none border border-gray-300"
                              onClick={(e: any) =>
                                handleStructureSampleOpen(
                                  e,
                                  details.left.sample
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 mr-1"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                />
                              </svg>
                              <span className="">
                                {details.left.sample.linkText}
                              </span>
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="md:px-2 md:py-4 md:w-1/2 mx-auto md:mx-left">
                    <div className="h-full  border-opacity-60 rounded-lg">
                      <div className="flex flex-col md:flex-row">
                        <div className="items-center justify-between mr-2 hidden md:flex">
                          {details.right.length > 0 ? (
                            <>
                              <div>
                                <div className="flex items-center justify-center w-8 h-8 border rounded-full text-gray-600 group-hover:bg-brand-torquise group-hover:text-white group-hover:border-brand-torquise">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* Only for mobile */}

                        <div className="items-center justify-between mr-2 mb-4 md:hidden">
                          {details.right.length > 0 ? (
                            <>
                              <div>
                                <div className="mx-auto flex items-center justify-center w-8 h-8 border rounded-full text-gray-600 group-hover:bg-brand-torquise group-hover:text-white group-hover:border-brand-torquise">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M19 14l-7 7m0 0l-7-7m7 7V3"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        {details.right.map((right: any) => (
                          <>
                            <div className="ml-2 p-4 duration-300 transform bg-white border-1 border rounded-2xl shadow-sm border-brand-torquise cursor-pointer group-hover:bg-brand-torquise">
                              <div className="flex items-center mb-2">
                                <p className="text-base font-semibold text-brand-darkblue">
                                  {right.heading}
                                </p>
                              </div>
                              <p className="mb-2 text-sm text-gray-900 hidden">
                                {right.description}
                              </p>
                              {right.redirect ? (
                                <>
                                  <button
                                    className="w-full justify-center mt-2 border-2 text-xs border-brand-torquise font-semibold py-2 px-4 inline-flex items-center rounded-3xl text-center text-white bg-brand-torquise hover:bg-white hover:text-brand-torquise focus:outline-none group-hover:bg-white group-hover:text-brand-torquise"
                                    onClick={() => {
                                      sendAmplitudeData(
                                        AMPLITUDE_EVENTS.StructurePageWritingSectionClick,
                                        {
                                          cg_section: right.redirect.url,
                                          school: school.id,
                                          question: question,
                                          publicType: props.publicType,
                                        }
                                      );
                                      publicType
                                        ? redirectTo(
                                            `${APP_ROUTES.ONBOARDING_SECTIONS}${right.redirect.url}`
                                          )
                                        : redirectTo(
                                            `${APP_ROUTES.DASHBOARD}${right.redirect.url}`
                                          );
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="w-3 h-3 mr-1"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                      />
                                    </svg>
                                    <span>{right.redirect.text}</span>
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}
                              <p className="hidden absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mt-3 -mr-3 font-bold rounded-full bg-brand-torquise">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-3 h-3 text-white"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                  />
                                </svg>
                              </p>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}
      </section>
    </>
  );
};
const SchoolsTab = ({ selectedSchools, publicType }: any) => {
  const history = useHistory();
  const redirectTo = (route: string): void => {
    history.push(route);
  };
  const [openTab, setOpenTab] = React.useState(1);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="pt-2 px-6 md:px-10 ">
            <button
              className="text-xs inline-flex items-center text-gray-400 focus:outline-none"
              onClick={() => {
                publicType
                  ? history.goBack()
                  : redirectTo(`${APP_ROUTES.DASHBOARD}`);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              {publicType ? <span>Go back</span> : <span>Go to overview</span>}
            </button>
          </div>
          <ul
            className="flex md:flex-wrap pt-2 px-6 md:px-10 border-b border-brand-lightblue list-none p-0 m-0 "
            role="tablist"
          >
            {selectedSchools.map((school: any, i: number) => (
              <>
                {schools[school.school].essayPresent && (
                  <li key={school.school} className="pr-2">
                    <a
                      className={
                        "text-xs md:text-base px-2 md:px-6 py-2 md:py-2 mb-1 md:mb-0 block font-semibold bg-gray-100 rounded-lg md:rounded-b-none " +
                        (openTab === i + 1
                          ? "text-white bg-brand-darkblue border border-b-none border-brand-lightblue rounded-t-lg"
                          : "text-brand-darkblue bg-white border border-b-none border-brand-lightblue rounded-t-lg")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(i + 1);
                        sendAmplitudeData(
                          AMPLITUDE_EVENTS.SchoolStructureTabClick,
                          { publicType: publicType, school: school.school }
                        );
                      }}
                      data-toggle="tab"
                      href={`link${i + 1}`}
                      role="tablist"
                    >
                      <span>
                      {schools[school.school].name}
                      </span>
                    </a>
                  </li>
                )}
              </>
            ))}
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-0 md:px-4 py-2 flex-auto">
              <div className="tab-content tab-space">
                {selectedSchools.slice().sort((a:any, b:any) => a.order - b.order).map((school: any, i: number) => (
                  <div
                    className={openTab === i + 1 ? "block" : "hidden"}
                    id={`link${i + 1}`}
                  >
                    <img
                      src={schools[school.school].img}
                      alt={school.school}
                      className="pr-4 w-24 mx-8 hidden"
                    />

                    <div className="my-2 mx-6">
                      <header className="pt-4 pb-0 font-semibold text-base md:text-xl text-brand-darkblue">
                        {schools[school.school].name}'s Essays Questions made
                        easy
                      </header>
                      <p className="text-brand-grey text-sm md:text-sm py-2">
                        Write your stories in this tool and create 100% unique
                        Essays with our consultants
                      </p>
                    </div>

                    {schools[school.school].essaySections &&
                      schools[school.school].essaySections.map(
                        (sectiondetails: any, i: number) => (
                          <>
                            <div className="my-4 py-0 md:mt-4 px-6 md:py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full">
                              <div className="max-w-full sm:mx-auto lg:max-w-full">
                                <div className="space-y-4">
                                  <Item
                                    title={`Essay ${i + 1}. ${
                                      sectiondetails.question
                                    }`}
                                    locked={sectiondetails.locked}
                                    description={sectiondetails.description}
                                    school={schools[school.school]}
                                    publicType={publicType}
                                  >
                                    {!sectiondetails.locked ? (
                                      <SchoolSection
                                        sectiondetails={sectiondetails}
                                        school={schools[school.school]}
                                        question={sectiondetails.question}
                                        publicType={publicType}
                                      />
                                    ) : (
                                      <>
                                        <div className="mt-2 text-sm px-2 inline-flex items-center text-center opacity-75">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-4 w-4 mr-2"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                            />
                                          </svg>
                                          <span>
                                            Unlocks after completing suggested
                                            sections mentioned in the first
                                            essay question
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </Item>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Item = ({
  title,
  children,
  locked,
  description,
  school,
  publicType,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`border border-brand-lightblue rounded-3xl shadow-sm ${
        locked ? "opacity-50" : ""
      }`}
    >
      <button
        type="button"
        title="Open the essay question"
        className="flex items-center justify-between w-full p-4 md:p-6 focus:outline-none"
        onClick={() => {
          setIsOpen(!isOpen);
          sendAmplitudeData(AMPLITUDE_EVENTS.SchoolStructureQuestionClick, {
            school: school.id,
            question: title,
            publicType: publicType,
          });
        }}
      >
        <p className="text-sm md:text-base text-brand-darkblue font-semibold text-left w-11/12">
          {title}{" "}
        </p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          {description && (
            <>
              <p className="text-sm text-left w-11/12 text-brand-grey pb-2 rounded relative hidden">
                {description}{" "}
              </p>
            </>
          )}
          <div className="">{children}</div>
        </div>
      )}
    </div>
  );
};

export default SchoolsTab;
