import React from "react";
import { IQuestion } from "../utils/defaultData";
import MCEditor from "../components/MCEditor";
import DragDocument from "./DragDocument";
import MultiInput from "./MultiInput";
import Tooltip from "./Tooltip";
import { IAnswer } from "../store/initialState";
import { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../utils/routeConstant";

const Question = (props: any): JSX.Element => {
  const { data, answer } = props;
  // console.log("data : ",data);
  // console.log("Answer : ", answer);
  const [saving, setSaving] = useState<string>("");

  /** method to save answer */
  const handleSave = (value: string, answer?: IAnswer): void => {
    setSaving(value);
    answer && props.handlePageAnswers(answer);
  };

  return (
    <div className="col-span-2">
      <div className="py-3 mt-2 items-center space-x-2">
        <span className="font-semibold inline text-brand-darkblue font-base">
          {data.question.replace(/MBA/g, localStorage.getItem("selectedProgram") || "MBA")}
        </span>

        {data.description && <Tooltip info={data.description} />}
        <div className="inline">
          {saving === "Saved" && (
            <span className="inline">
              <svg
                className="h-3.5 w-3.5 inline ml-1 question-check font-medium"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 132 132"
              >
                <circle
                  cx="65.1"
                  cy="65.1"
                  r="62.1"
                  fill="none"
                  stroke="#51cdc2"
                  strokeMiterlimit="20"
                  strokeWidth="10"
                  className="path circle"
                ></circle>
                <path
                  fill="none"
                  stroke="#51cdc2"
                  strokeLinecap="round"
                  strokeMiterlimit="20"
                  strokeWidth="10"
                  d="M100.2 40.2L51.5 88.8 29.8 67.5"
                  className="path check"
                ></path>
              </svg>
            </span>
          )}
          <span className="pl-2 text-sm text-brand-torquise">{saving}</span>
        </div>
      </div>
      <div>
        {data.subheading ? (
          <div
            className="text-sm text-brand-grey mb-4 -mt-2"
            dangerouslySetInnerHTML={{ __html: data.subheading }}
          />
        ) : (
          <></>
        )}
      </div>
      {data.type === "editor" && (
        <>
          <div className="relative">
            {props.publicType ? (
              <>
                <div className=" z-10 opacity-60 absolute w-full h-full pt-0 mx-auto inset-y-0 inset-x-0 bg-gray-100  text-white"></div>
                <div className=" z-20 absolute w-full h-20 inset-x-0 inset-y-0 top-0 right-0 left-0 right-0 m-auto py-4 inset-x-0 text-center">
                  <div className="text-brand-darkblue flex justify-center">
                    <Link
                      className={`text-sm capitalize py-3 mb-5 font-bold flex justify-center space-x-2 px-6`}
                      to={APP_ROUTES.SIGNUP_DIRECT}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2.5}
                          d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                        />
                      </svg>
                      <div className="underline">
                        {"Register to start writing your essay draft"}
                      </div>
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            <MCEditor
              placeholder={data?.placeholder}
              questionId={data.id}
              answer={answer || data.defaultvalue}
              saving={saving}
              handleSave={handleSave}
              readonly={false}
            />
            {props.updatedAt && (
              <div
                className="text-xs mt-1 text-right text-gray-300"
                title={moment(props.updatedAt).format("MMM D YYYY, h:mm:ss A")}
              >
                Last saved at{" "}
                <span>{moment(props.updatedAt).format("MMM D, h:mm A")}</span>
              </div>
            )}
          </div>
        </>
      )}
      {data.type === "upload-file" && (
        <DragDocument
          questionId={data.id}
          answer={answer}
          saving={saving}
          handleSave={handleSave}
        />
      )}
      {data.type === "multi-input-3" && (
        <MultiInput
          numberofRepetation={3}
          consultantDataAvailable={true}
          type="email"
          name="email"
          placeholder={data?.placeholder || "email"}
          questionId={data.id}
          answer={answer}
          saving={saving}
          handleSave={handleSave}
        />
      )}
      {data.type === "input" && (
        <MultiInput
          numberofRepetation={1}
          type="text"
          name="link"
          placeholder={data?.placeholder || "link"}
          questionId={data.id}
          answer={answer}
          saving={saving}
          handleSave={handleSave}
        />
      )}
    </div>
  );
};

export default Question;
