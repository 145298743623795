import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import NotificationDropdown from "./Notification";
import UserDropdown from "./UserDropdown";
import {
  ICategory,
  Status,
  StatusColorMap,
  comingSoon,
  ISubCategory,
} from "../utils/defaultData";
import { InitialStore, Source } from "../store/initialState";
import { APP_ROUTES } from "../utils/routeConstant";
import { sendAmplitudeData } from '../utils/amplitideUtils';
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
import { CONSTANTS, PHASE_TYPES } from "../utils/Constants";
import { findConsultantMeetingLink, openInNewTab } from "../utils/helpers";

const Sidebar = (props: any): JSX.Element => {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const location = useLocation();
  const history = useHistory();
  const dashboard = useSelector((state: InitialStore) => state?.dashboard);
  const userProfile = useSelector((state: InitialStore) => state?.userProfile);
  const [dashboardData, setDashboardData] = useState<any>({});
  const [meetingLinks, setMeetingLinks] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [sectionLockedModal, setSectionLockedModal] = useState(false);
  let rootKey = 'start/sections';
  if(userProfile && userProfile.id) {
      rootKey = 'dashboard';
  }

  const handleClose = (): void => {
    setOpenModal(false);
    setSectionLockedModal(false);
  };

  const handleNavClick = (
    e: React.SyntheticEvent,
    category: ICategory,
    link: string,
    status: string
  ): void => {
    e.preventDefault();
    if (status !== Status.LOCKED) {
      sendAmplitudeData(AMPLITUDE_EVENTS.ChangePhaseClick, "");

      if(category?.phaseType && category?.phaseType === PHASE_TYPES.link) {
        let finalLink = "";

        // Phase 2 and Consultants
        const consultants = dashboard?.consultants;
        const meetingLinkConsultant = findConsultantMeetingLink(consultants);

        // Phase 5 Branding and DocumentLink
        const brandingDocLink = dashboard?.profileData?.brandingDocId;

        // Phase 4 and Content Gathering Specialist
        const meetingLinkCgs = "https://meetings.hubspot.com/kishan-navale";

        // Check for phase
        if(category.key === "phase2") {
          finalLink = meetingLinkConsultant;
        }

        if(category.key === "phase4") {
          finalLink = meetingLinkConsultant ? meetingLinkConsultant : meetingLinkCgs;
        }

        if(category.key === "phase5" && brandingDocLink) {
          finalLink = 'https://docs.google.com/document/d/' + brandingDocLink;
        }

        if(category.key === "phase7") {
          finalLink = "/dashboard/schools-content";
        }

        if(consultants && consultants.length > 0 && finalLink) {
          if(category.key === "phase7") {
            history.push(finalLink);
          }
          else
            openInNewTab(finalLink);
        }
        else {
          setSectionLockedModal(true);
        }
      }
      else {
        history.push(`/dashboard/${link}`);
      }
    } else if (comingSoon.includes(link)) {
      sendAmplitudeData(AMPLITUDE_EVENTS.ComingSoonClick, "");
      setOpenModal(true);
    } else if(status === Status.LOCKED) {
      setSectionLockedModal(true);
    }
  };

  useEffect(() => {
    dashboard && setDashboardData({ ...dashboard });
  }, [dashboard]);
  return (
    <>
      <nav className="bg-brand-darkblue md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <img alt={"bar icon"} className="w-6 h-6" src="/images/bars.svg" />
          </button>
          {/* Brand */}
          <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm capitalize font-medium pt-0 pl-6">
            <Link to={APP_ROUTES.DASHBOARD}>
              <img
                height="56"
                width="144"
                className="w-36 md:w-40 h-auto"
                src="/logo.png"
                alt="logo"
              />
            </Link>
          </div>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none list-none p-0 m-0 ">
          <li className="inline-block relative">
             <button className="focus:outline-none hidden" onClick={() => props.handlSchoolSelectionOpen()} title="FAQs">
              <div className="w-8 h-8 bg-brand-torquise text-white inline-flex items-center justify-center rounded-full hidden">
                <img src="/images/schools.svg" className="w-5 h-5 text-brand-darkblue" />
              </div>
            </button>
            </li>
            <button className="focus:outline-none pl-2" onClick={() => props.handleFaqOpen()} title="FAQs">
              <div className="w-8 h-8 bg-brand-torquise text-white inline-flex items-center justify-center rounded-full">
                <img src="/images/questionmark.svg" className="w-5 h-5 text-brand-darkblue" />
              </div>
            </button>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "bg-brand-darkblue md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="bg-brand-darkblue md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap justify-end">
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <img
                      alt="icon"
                      className="w-6 h-6"
                      src="/images/times.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
            {/* Navigation */}
            <ul className="md:min-w-full list-none p-0 m-0 ">
              {userProfile.isConsultant && <li className="items-center">
                <Link
                  className={`text-sm capitalize py-3 mb-5 font-bold flex items-center space-x-2 px-4 text-white`}
                  onClick={() => setCollapseShow("hidden")}
                  to={APP_ROUTES.ALL_USERS}
                >
                  <img
                    src={
                      `/images/userSidebar.svg`
                    }
                    height="24"
                    width="24"
                    className="w-5 h-5"
                    alt={"all applicants"}
                  />
                  <div>{"All applicants"}</div>
                </Link>
              </li>}
              {<li className="items-center md: hidden">
                <Link
                  className={`text-sm capitalize py-3 mb-5 font-bold flex items-center space-x-2 px-4 text-white`}
                  onClick={() => setCollapseShow("hidden")}
                  to={APP_ROUTES.ALL_USERS}
                >
                  <img
                    src={
                      `/images/userSidebar.svg`
                    }
                    height="24"
                    width="24"
                    className="w-5 h-5"
                    alt={"all applicants"}
                  />
                  <div>{"All applicants"}</div>
                </Link>
              </li>}
              <li key="overview" className="items-center">
                <Link
                  className={`text-sm capitalize py-2.5 font-medium flex items-center space-x-2 px-4 ${location.pathname === "/dashboard"
                      ? "border-l-4 text-brand-torquise border-brand-torquise"
                      : "text-white"
                    }`}
                  onClick={() => {setCollapseShow("hidden")
                  sendAmplitudeData(AMPLITUDE_EVENTS.DasboardRoute, {publicType:props.publicType});
                }}
                  to={`/${rootKey}`}
                >
                  <img
                    src={
                      location.pathname === "/dashboard"
                        ? `/images/overview.svg`
                        : `/images/overview-white.svg`
                    }
                    height="20"
                    width="20"
                    className="w-5 h-5"
                    alt={"overview"}
                  />
                  <div>{"Overview"}</div>
                </Link>
              </li>
              {dashboardData &&
                dashboardData.categories?.map((category: ICategory) => {
                  return (
                    <React.Fragment key={category.key}>
                      {!comingSoon.includes(category.key) && (
                        <li className="items-center">
                          <Link
                            className={`text-sm capitalize py-2.5 font-medium flex items-center space-x-2 px-4 ${location.pathname.includes(
                              `/${rootKey}${category.key}`
                            )
                                ? `border-l-4 border-${StatusColorMap[category?.status]
                                }`
                                : ``
                              } ${`text-${StatusColorMap[category?.status]}`} ${category.status === Status.LOCKED
                                ? "pointer-events-none"
                                : ""
                              }`}
                            to={
                              category.status !== Status.LOCKED
                                ? `/${rootKey}/${category.key}`
                                : `/${rootKey}`
                            }
                            onClick={(e) => {
                              setCollapseShow("hidden")
                              handleNavClick(e, category, category.key, category.status)
                              sendAmplitudeData(`dashboard-${category.key}-route`, {publicType: props.publicType});
                          }}
                          >
                            <img
                              width="20"
                              height="20"
                              src={`/images/${category.type
                                }-${category?.status.toLowerCase()}.svg`}
                              className="w-5 h-5"
                              alt={"status img"}
                            />
                            <div>{category.name}</div>
                          </Link>
                          {category.nestedCategories &&
                            category.status !== Status.LOCKED  && (
                              <ul className={` md:min-w-full list-disc text-white px-12 ${location.pathname.includes(
                                `${category.key}`) ? `}` : `hidden`}`
                                }>
                                {category.nestedCategories.map(
                                  (nestedCategory: ISubCategory) => {
                                    return (
                                      <li key={`${category.key}-${nestedCategory.key}`}>
                                        <Link
                                          className={`text-sm capitalize py-2.5 flex items-center space-x-2 text-white ${location.pathname.includes(
                                            nestedCategory.key
                                          )
                                              ? "font-bold"
                                              : "font-medium"
                                            }`}
                                          to={`/${rootKey}/${category.key}/${nestedCategory.key}`}
                                          onClick={(e) =>{
                                            setCollapseShow("hidden")
                                            sendAmplitudeData(`${category.key}-${nestedCategory.key}-route`, {publicType: props.publicType});
                                          }
                                        }
                                        >
                                          {nestedCategory.name.replace(/MBA/g, localStorage.getItem("selectedProgram") || "MBA")}
                                        </Link>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            )}
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}
              
            </ul>
            {!userProfile.isConsultant && userProfile.source === Source.Direct ? (
              <>
                {/* <div className="text-brand-grey text-xs px-6 border-brand-grey mt-4">
                Stuck anywhere while writing the answers?
                </div>
                <div className="text-brand-grey text-xs px-6 my-4 mt-6">
                  <a href={CONSTANTS.SCHEDULE_CALL_LINK}
                  onClick={() =>{
                    sendAmplitudeData(AMPLITUDE_EVENTS.ScheduleCallCTA, {route: window.location.href});
                  }}
                  target="_blank" 
                  className="text-xs cursor-pointer border-2 font-medium border-brand-torquise py-2 px-4 rounded-3xl text-center w-36 text-brand-torquise bg-transparent hover:bg-brand-torquise hover:text-white focus:outline-none">
                    Schedule a call
                  </a>
                </div> */}
              </>
              
            ) : (
              <></>
            )}
            
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
